

















































import { NotificationMixin } from '../mixins';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
  name: 'ResetPassword',
})
export default class ResetPassword extends Mixins(NotificationMixin) {
  passwordHint = {
    content: `
        <span>Must contain:</span>
        <ul>
            <li>At least 8 characters</li>
            <li>At least 1 uppercase character</li>
            <li>At least 1 lowercase character</li>
            <li>At least 1 number</li>
        </ul>
    `,
    html: true,
  };

  user = {
    password: '',
    confirmPassword: '',
  };

  // METHODS
  submit() {
    const { password, confirmPassword } = this.user;
    const token = this.$route.params.token;
    return this.$store
      .dispatch('auth/resetPassword', { token, password, confirmPassword })
      .then(() => {
        this.notifySuccess('Successfully reset password. Please login.');
        this.$router.push({ name: 'login' });
      })
      .catch((err) => {
        const errMessage = err.message || 'Failed to reset password.';
        this.notifyError(errMessage);
      });
  }
}
